import { BookingJSON, BookingRelationshipsJSON, BookingStatus } from '@jurnee/common/src/entities/Booking';
import BookingReviewEntity from '@jurnee/common/src/entities/BookingReview';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { Currency, CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getInvoicesTotal } from '@jurnee/common/src/utils/bookingInvoices';
import { getBookingStatus } from '@jurnee/common/src/utils/bookings';
import { getPlaceholderImagePath, hasCustomRequestType, hasExperienceType } from '@jurnee/common/src/utils/bookingsItems';
import { getExternalCostsTotal } from '@jurnee/common/src/utils/externalCosts';
import { getPriceRange } from '@jurnee/common/src/utils/propositions';
import { isPropositionsGroupOpen } from '@jurnee/common/src/utils/propositionsGroups';

export const DEFAULT_BOOKING_DEADLINE = 7;
export const DEFAULT_TARGET_CURRENCY = 'EUR';
export const DEFAULT_PAY_VIA_JURNEE_FEE_RATE = 0.05;

export type BookingStep = 'PLANNING' | 'COMMITMENT' | 'CONFIRMATION' | 'WAITING_EVENT' | 'FINISHED';

export type BookingsTabs =
  | 'all'
  | 'confirmed'
  | 'pending'
  | 'actionRequired'
  | 'draft'
  | 'externalCosts'
  | 'past'

export type CommitmentTypes = 'APPROVAL_REQUEST' | 'CREDIT_CARD' | 'QUOTE_SIGNATURE';

export function getAvgBookingReviewsRating(bookingsReviews: Pick<BookingReviewEntity, 'rating'>[]) {
  return bookingsReviews.reduce((total, { rating }) => total + rating, 0) / bookingsReviews.length;
}

export function getBookingStep(status: BookingStatus): BookingStep {
  switch(status) {
  case 'FINISHED': return 'FINISHED';
  case 'CONFIRMED': return 'WAITING_EVENT';
  case 'CONFIRMING_AVAILABILITY': return 'CONFIRMATION';
  default: return 'PLANNING';
  }
}

export function getCommitmentType(company: CompanyJSON, user: UserDetails): CommitmentTypes {
  if (user.approvalProcessId) {
    return 'APPROVAL_REQUEST';
  }

  if (company.creditCardEnabled) {
    return 'CREDIT_CARD';
  }

  return 'QUOTE_SIGNATURE';
}

export function filterByStatuses(bookings: BookingJSON[], relationships: BookingRelationshipsJSON, statuses: BookingStatus[]) {
  return bookings.filter(booking => {
    const filteredRelationships = filterRelationshipsByBookingId(booking.id, relationships);
    const bookingStatus = getBookingStatus(booking, filteredRelationships);

    return statuses.includes(bookingStatus);
  });
}

export function isBookingCancelable({ bookingsItems, canceledAt }: BookingJSON) {
  return canceledAt === null && bookingsItems.every(({ status }) => {
    return ['PROCESSING', 'INITIAL'].includes(status);
  });
}

export function isBookingEditDisabled(booking: BookingJSON, relationships: BookingRelationshipsJSON) {
  const status = getBookingStatus(booking, relationships);

  return !['DRAFT', 'PROCESSING', 'EXTERNAL_COSTS'].includes(status);
}

export function getBookingImagePaths({ bookingsItems }: BookingJSON) {
  const paths: string[] = [];

  for (const bookingItem of bookingsItems) {
    if (bookingItem.bookingsItemsImages.length > 0) {
      paths.push(bookingItem.bookingsItemsImages[0].image.path);

      continue;
    }

    if (hasExperienceType(bookingItem)) {
      continue;
    }

    if (hasCustomRequestType(bookingItem)) {
      continue;
    }

    paths.push(getPlaceholderImagePath(bookingItem.bookingItemType.key));
  }

  return paths;
}

export function filterRelationshipsByBookingId(id: BookingJSON['id'], relationships: Pick<BookingRelationshipsJSON, 'approvalRequests' | 'bookingsInvoices' | 'bookingsReviews' | 'externalCosts' | 'propositions' | 'propositionsGroups' | 'quotes'>): Pick<BookingRelationshipsJSON, 'approvalRequests' | 'bookingsInvoices' | 'bookingsReviews' | 'externalCosts' | 'propositions' | 'propositionsGroups' | 'quotes'> {
  const approvalRequests = relationships.approvalRequests.filter(({ bookingId }) => bookingId === id);
  const bookingsInvoices = relationships.bookingsInvoices.filter(({ bookingId }) => bookingId === id);
  const bookingsReviews = relationships.bookingsReviews.filter(({ bookingId }) => bookingId === id);
  const externalCosts = relationships.externalCosts.filter(({ bookingId }) => bookingId === id);
  const propositionsGroups = relationships.propositionsGroups.filter(({ bookingId }) => bookingId === id);
  const propositionsGroupIds = propositionsGroups.map(({ id }) => id);
  const propositions = relationships.propositions.filter(({ propositionsGroupId }) => propositionsGroupIds.includes(propositionsGroupId));
  const quotes = relationships.quotes.filter(({ providerInvoiceId }) => bookingsInvoices.map(({ providerInvoiceId }) => providerInvoiceId).includes(providerInvoiceId));

  return {
    approvalRequests,
    bookingsInvoices,
    bookingsReviews,
    externalCosts,
    propositions,
    propositionsGroups,
    quotes
  };
}

export function getBookingPriceRange(relationships: Pick<BookingRelationshipsJSON, 'propositions' | 'propositionsGroups' | 'bookingsInvoices' | 'externalCosts'>, currencies: CurrencyExchangeRates, currency: Currency): { from: number, to: number } {
  const { bookingsInvoices, externalCosts } = relationships;

  const bookingInvoicesTotal = getInvoicesTotal(bookingsInvoices);
  const externalCostsTotal = getExternalCostsTotal(externalCosts, currencies, currency);
  const bookingTotal = bookingInvoicesTotal + externalCostsTotal;
  const propositionsGroups = relationships.propositionsGroups.filter(isPropositionsGroupOpen);
  const propositions = relationships.propositions.filter(({ propositionsGroupId, declinedAt }) =>
    propositionsGroups.some(({ id })=> id === propositionsGroupId) &&
    !declinedAt
  );

  if (propositionsGroups.length === 0) {
    return { from: bookingTotal, to: bookingTotal };
  }

  const { from, to } = getPriceRange(propositions);

  return {
    from: from + bookingTotal,
    to: to + bookingTotal
  };
}

export function getPropositionsByPropositionsGroupId(propositions: PropositionJSON[], propositionsGroupId: PropositionsGroupJSON['id']) {
  return propositions.filter(proposition =>
    proposition.propositionsGroupId === propositionsGroupId
  );
}