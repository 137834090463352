import {
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { isAdmin } from '@jurnee/common/src/utils/user';
import { getApprovalRequestsCategorizedByUserId, getApprovalRequestsFetchStatusSelector } from '@jurnee/dashboard/src/store/approvalRequests/approvalRequests.selectors';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EmptyState from 'src/components/EmptyState';
import { useAppDispatch } from 'src/store';
import { getApprovalRequests } from 'src/store/approvalRequests/approvalRequests.thunks';
import { getBudgetsFetchStatusSelector } from 'src/store/budgets/budgets.selectors';
import { getBudgets, getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { ApprovalRequestCard } from './ApprovalRequestCard';
import { ApprovalRequestsHeader } from './ApprovalRequestsHeader';

export interface TabItem {
  label: string;
  key: string;
}

export function ApprovalRequests() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('approvalRequests');

  const user = useSelector(getUserSelector);
  const approvalRequestsCategorized = useSelector(getApprovalRequestsCategorizedByUserId(user.id));
  const approvalResquestsFetchStatus = useSelector(getApprovalRequestsFetchStatusSelector);
  const budgetsFetchStatus = useSelector(getBudgetsFetchStatusSelector);

  const isLoading = [
    approvalResquestsFetchStatus,
    budgetsFetchStatus
  ].some(fetchStatus => fetchStatus !== 'FETCHED');

  const tabs = [{
    label: t('header.tabs.pending'),
    key: 'pending'
  }, {
    label: t('header.tabs.processed'),
    key: 'processed'
  }];

  useEffect(() => {
    dispatch(getApprovalRequests());
    dispatch(isAdmin(user) ? getBudgets() : getUserBudgets());
  }, []);

  function getApprovalRequestsByKey(key: TabItem['key']) {
    const approvalRequests = key === 'processed' ?
      approvalRequestsCategorized.processed :
      approvalRequestsCategorized.pending;

    if (approvalRequests.length === 0) {
      return <EmptyState
        imagePath="/assets/illustrations/approval.svg"
        heading={t(`${key}.emptyState.heading`)}
        description={t(`${key}.emptyState.description`)}
      />;
    }

    return (
      <VStack spacing={5}>
        {
          approvalRequests.map(approvalRequest => (
            <ApprovalRequestCard
              key={approvalRequest.id}
              approvalRequest={approvalRequest}
            />
          ))
        }
      </VStack>
    );
  }

  return (
    <main>
      <Tabs>
        <ApprovalRequestsHeader tabs={tabs} />

        <TabPanels>
          {
            tabs.map(({ key }) => (
              <TabPanel key={key}>
                {
                  isLoading ?
                    <Loader h={400} /> :
                    getApprovalRequestsByKey(key)
                }
              </TabPanel>
            ))
          }
        </TabPanels>
      </Tabs>
    </main>
  );
}