import { HStack, Modal, ModalBody, ModalContent, ModalOverlay, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PlaceResultDTO } from '@jurnee/common/src/dtos/places';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HowtoCard } from 'src/components/HowtoCard';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { createBooking } from 'src/store/bookings/bookings.thunks';
import { getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { createPropositionsGroupThunk } from 'src/store/propositionsGroups/propositionsGroups.thunks';
import { ExperienceCategory } from 'src/utils/experiences';
import { CustomItemFormData, Form } from './Form';

interface Props {
  type: ExperienceCategory['type'];
  children: React.ReactElement;
  booking?: BookingJSON;
  partnerPlace?: PlaceResultDTO;
}

export function CustomItemModal(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const { t } = useTranslation('booking', { keyPrefix: 'modals.customItem' });

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [sessionToken, setSessionToken] = useState(null);

  const [budgets, setBudgets] = useState<BudgetJSON[]>([]);

  function onClose() {
    setIsOpen(false);
  }

  async function fetchUserBudgets() {
    try {
      const { list } = await dispatch(getUserBudgets()).unwrap();
      setBudgets(list);
    } catch (error) {
      toast(getErrorToast(t('toasts.fetchUserBudgets.error')));
    }
  }

  async function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setIsOpen(true);
    setSessionToken(crypto.randomUUID());

    setIsLoading(true);
    await fetchUserBudgets();
    setIsLoading(false);

    dispatch(trackEvent({
      name: `opened_custom_request_modal`
    }));
  }

  async function dispatchCreatePropositionsGroup({ propositionsGroup }: CustomItemFormData) {
    return dispatch(
      createPropositionsGroupThunk({
        bookingId: props.booking.id,
        data: propositionsGroup
      })
    ).unwrap();
  }

  async function dispatchCreateBooking({ booking, propositionsGroup }: CustomItemFormData) {
    const createdBooking = await dispatch(
      createBooking({
        body: {
          name: booking.name,
          budgetId: booking.budgetId || null,
          origin: 'DASHBOARD_CUSTOM_REQUEST',
          propositionsGroups: [propositionsGroup]
        }
      })
    ).unwrap();

    return createdBooking;
  }

  async function onSubmit(formData: CustomItemFormData) {
    try {
      const { data, relationships } = props.booking ?
        await dispatchCreatePropositionsGroup(formData) :
        await dispatchCreateBooking(formData);

      toast(getSuccessToast(t('toasts.success')));
      onClose();

      const search = new URLSearchParams({
        tab: 'requests',
        propositionsGroupId: relationships.propositionsGroups.at(-1).id.toString()
      });

      navigate({
        pathname: `/bookings/${data.id}`,
        search: search.toString()
      });
    } catch(error) {
      toast(getErrorToast(t('toasts.error'), error.message));
    }
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="20px" border={0} bg="white">
            <HStack minH={560} alignItems="stretch" spacing={5}>
              <HowtoCard type={props.type} partnerPlace={props.partnerPlace} />

              {
                isLoading ?
                  <Loader flexDirection="column" h="initial" /> :
                  <Form
                    type={props.type}
                    sessionToken={sessionToken}
                    booking={props.booking}
                    partnerPlace={props.partnerPlace}
                    budgets={budgets}
                    onSubmit={onSubmit}
                    onClose={onClose}
                  />
              }
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}