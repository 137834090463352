import { TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { TabLabel } from '@jurnee/common/src/components/TabLabel';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { getOrganizerIds } from '@jurnee/common/src/utils/bookings';
import { getDefaultTabIndex } from '@jurnee/common/src/utils/tabs';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { getTasks } from 'src/api/tasks';
import { useAppDispatch } from 'src/store';
import { getBookingDetailsEntitySelector, getBookingDetailsInvoiceToPay, getBookingDetailsRelationshipsSelector, getBookingDetailsSelector, getBookingFetchStatusSelector } from 'src/store/bookingDetails/bookingDetails.selectors';
import { getBooking } from 'src/store/bookingDetails/bookingDetails.thunks';
import { getBookingParticipantsThunk } from 'src/store/bookingParticipants/bookingParticipants.thunks';
import { getEmployeesByIds, getEmployeesFetchStatusSelector } from 'src/store/employees/employees.selectors';
import { getEmployees } from 'src/store/employees/employees.thunks';
import { getEntitiesFetchStatusSelector } from 'src/store/entities/entities.selectors';
import { getEntities } from 'src/store/entities/entities.thunks';
import { getUserBudgetBreakdownsByBookingId } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.thunks';
import { getPropositionsByPropositionsGroupId } from 'src/utils/booking';
import { BookingDetailsHeader } from './BookingDetailsHeader';
import { BookingSteps } from './BookingSteps';
import { ExternalCosts } from './ExternalCosts';
import { Organizers } from './Organizers';
import { Registration } from './Registration';
import { Requests } from './Requests';
import { Surveys } from './Surveys';
import { Tasks } from './Tasks';

export function BookingDetails() {
  const params = useParams();
  const bookingId = Number(params.bookingId);
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('booking');

  const booking = useSelector(getBookingDetailsSelector);
  const entity = useSelector(getBookingDetailsEntitySelector);
  const relationships = useSelector(getBookingDetailsRelationshipsSelector);
  const organizerIds = useMemo(() => booking ? getOrganizerIds(booking) : [], [booking]);
  const organizers = useSelector(getEmployeesByIds(organizerIds));
  const invoiceToPay = useSelector(getBookingDetailsInvoiceToPay);

  const bookingFetchStatus = useSelector(getBookingFetchStatusSelector);
  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);
  const entitiesFetchStatus = useSelector(getEntitiesFetchStatusSelector);

  const [tasks, setTasks] = useState<TaskJSON[]>([]);
  const [areTasksLoading, setAreTasksLoading] = useState(true);

  const hasPropositionsGroups = relationships?.propositionsGroups.length > 0;

  const tabs = useMemo(
    () => {
      const requestsCount = (relationships?.propositionsGroups || []).filter(({ id, status }) => {
        const propositions = getPropositionsByPropositionsGroupId(relationships.propositions, id);
        return status === 'OPEN' && propositions.length > 0 && !propositions.every(({ declinedAt }) => declinedAt);
      }).length;

      const tasksCount = tasks.filter(task => ['TODO', 'IN_PROGRESS'].includes(task.status)).length;

      const tabs: { label: string | JSX.Element, key: string }[] = [{
        label: t('tabs.overview'),
        key: 'overview'
      }];

      if (hasPropositionsGroups) {
        tabs.push({
          label: <TabLabel label={t('tabs.requests')} count={requestsCount} />,
          key: 'requests'
        });
      }

      tabs.push({
        label: <TabLabel label={t('tabs.tasks')} count={tasksCount} />,
        key: 'collaboration'
      }, {
        label: t('tabs.organizers'),
        key: 'organizers'
      }, {
        label: t('tabs.registration'),
        key: 'registration'
      }, {
        label: t('tabs.surveys'),
        key: 'surveys'
      }, {
        label: t('tabs.externalCosts'),
        key: 'externalCosts'
      });

      return tabs;
    },
    [tasks, relationships]
  );

  async function fetchTasks() {
    try {
      const { list } = await getTasks({ bookingId });
      setTasks(list);
      setAreTasksLoading(false);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchTasks.error'), error.message));
    }
  }

  useEffect(() => {
    dispatch(getBooking(bookingId));
    dispatch(getEntities());
    dispatch(getEmployees());
    dispatch(getBookingParticipantsThunk({ bookingId }));
    dispatch(getUserBudgetBreakdownsByBookingId({ bookingId }));
    fetchTasks();
  }, [params.bookingId]);

  const isLoading = [
    bookingFetchStatus,
    employeesFetchStatus,
    entitiesFetchStatus,
  ].some(fetchStatus => fetchStatus !== 'FETCHED');

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <Tabs
        minH="100%"
        index={getDefaultTabIndex(tabs, searchParams.toString())}
        onChange={index => setSearchParams({ tab: tabs[index].key })}
        isLazy={true}
      >
        <BookingDetailsHeader booking={booking} relationships={relationships} tabs={tabs} />
        <TabPanels display="flex" flexGrow={1}>
          <TabPanel display="flex" alignItems="flex-start" justifyContent="center">
            <BookingSteps
              booking={booking}
              entity={entity}
              invoiceToPay={invoiceToPay}
              relationships={relationships}
            />
          </TabPanel>
          {
            hasPropositionsGroups &&
              <TabPanel>
                <Requests bookingId={booking.id} relationships={relationships} />
              </TabPanel>
          }
          <TabPanel>
            <Tasks
              booking={booking}
              tasks={tasks}
              organizers={organizers}
              isLoading={areTasksLoading}
              onChange={setTasks}
            />
          </TabPanel>
          <TabPanel>
            <Organizers booking={booking} />
          </TabPanel>
          <TabPanel>
            <Registration booking={booking} />
          </TabPanel>
          <TabPanel>
            <Surveys booking={booking} />
          </TabPanel>
          <TabPanel>
            <ExternalCosts booking={booking} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </main>
  );
}