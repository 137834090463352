import { HStack, Modal, ModalBody, ModalContent, ModalOverlay, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPartner } from 'src/api/partners';
import { createPartnerDocument, uploadPartnerDocument } from 'src/api/partnersDocuments';
import { HowtoCard } from 'src/components/HowtoCard';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { createBooking } from 'src/store/bookings/bookings.thunks';
import { getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { getCurrenciesFetchStatusSelector, getCurrenciesSelector } from 'src/store/currencies/currencies.selectors';
import { getCurrenciesThunk } from 'src/store/currencies/currencies.thunk';
import { createPropositionsGroupThunk } from 'src/store/propositionsGroups/propositionsGroups.thunks';
import { DEFAULT_TARGET_CURRENCY } from 'src/utils/booking';
import { FeeDescription } from './FeeDescription';
import { Form, PayViaJurneeFormData } from './Form';

interface Props {
  children: React.ReactElement;
  booking?: BookingJSON;
}

export function PayViaJurneeModal(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation('booking', { keyPrefix: 'modals.payViaJurnee' });

  const currencies = useSelector(getCurrenciesSelector);
  const currenciesFetchStatus = useSelector(getCurrenciesFetchStatusSelector);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [sessionToken, setSessionToken] = useState(null);

  const [budgets, setBudgets] = useState<BudgetJSON[]>([]);

  const [total, setTotal] = useState<number>(null);
  const [currency, setCurrency] = useState<string>(DEFAULT_TARGET_CURRENCY);
  const [rate, setRate] = useState<number>(1);

  function onClose() {
    setIsOpen(false);
  }

  async function fetchUserBudgets() {
    try {
      const { list } = await dispatch(getUserBudgets()).unwrap();
      setBudgets(list);
    } catch (error) {
      toast(getErrorToast(t('toasts.fetchUserBudgets.error')));
    }
  }

  async function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setIsOpen(true);
    setSessionToken(crypto.randomUUID());

    if (currenciesFetchStatus !== 'FETCHED') {
      dispatch(getCurrenciesThunk());
    }

    setIsLoading(true);
    await fetchUserBudgets();
    setIsLoading(false);

    dispatch(trackEvent({
      name: `opened_pay_via_jurnee_modal`
    }));
  }

  async function dispatchCreatePropositionsGroup(bookingId: BookingJSON['id'], data: PayViaJurneeFormData['propositionsGroup']) {
    return dispatch(
      createPropositionsGroupThunk({
        bookingId,
        data
      })
    ).unwrap();
  }

  async function dispatchCreateBooking(data: PayViaJurneeFormData['booking']) {
    const booking = await dispatch(
      createBooking({
        body: {
          name: data.name,
          budgetId: data.budgetId || null,
          origin: 'DASHBOARD_CUSTOM_REQUEST'
        }
      })
    ).unwrap();

    return booking.data;
  }

  function onAmountChange(data: { total: number, currency: string, rate: number }) {
    setTotal(data.total);
    setCurrency(data.currency);
    setRate(data.rate);
  }

  async function onSubmit(data: PayViaJurneeFormData) {
    try {
      const booking = props.booking ? props.booking : await dispatchCreateBooking(data.booking);

      const partner = await createPartner({ providerPlaceId: data.providerPlaceId });

      const { path } = await uploadPartnerDocument({ bookingId: booking.id }, data.quote.file);

      const partnerDocument = await createPartnerDocument({ bookingId: booking.id }, {
        amount: data.quote.amount,
        currencyId: data.quote.currencyId,
        partnerId: partner.id,
        path,
        type: 'QUOTE',
        filename: data.quote.file.name,
        size: data.quote.file.size
      });

      const updatedBooking = await dispatchCreatePropositionsGroup(booking.id, {
        ...data.propositionsGroup,
        partnerDocumentId: partnerDocument.id
      });

      toast(getSuccessToast(t('toasts.success')));
      onClose();

      const search = new URLSearchParams({
        tab: 'requests',
        propositionsGroupId: updatedBooking.relationships.propositionsGroups.at(-1).id.toString()
      });

      navigate({
        pathname: `/bookings/${updatedBooking.data.id}`,
        search: search.toString()
      });
    } catch(error) {
      toast(getErrorToast(t('toasts.error'), error.message));
    }
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="20px" border={0} bg="white">
            <HStack minH={500} alignItems="stretch" spacing={5}>
              <HowtoCard type="PAY_VIA_JURNEE">
                <FeeDescription total={total} currency={currency} rate={rate} />
              </HowtoCard>

              {
                isLoading || currenciesFetchStatus !== 'FETCHED' ?
                  <Loader flexDirection="column" h="initial" /> :
                  <Form
                    sessionToken={sessionToken}
                    booking={props.booking}
                    budgets={budgets}
                    currencies={currencies}
                    onAmountChange={onAmountChange}
                    onSubmit={onSubmit}
                    onClose={onClose}
                  />
              }
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}