import { Flex, Heading, HStack, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EventSchedule from 'src/components/BookingDetails/EventSchedule';
import { PropositionsGroups } from 'src/components/BookingDetails/PropositionsGroups';
import { ExploreCard } from 'src/components/ExploreCard';
import { isBookingEditDisabled } from 'src/utils/booking';
import { BookingSummary } from './BookingSummary';
import { ExploreSection } from './ExploreSection';

interface Props {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  bookingInvoice: BookingInvoiceJSON;
  onRemoveBookingItem(bookingItemId: BookingItemJSON['id']): void;
  onNext(): void;
}

export function PlanningStep({ booking, relationships, bookingInvoice, onNext, onRemoveBookingItem }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation('booking');
  const [searchParams] = useSearchParams();

  const hasBookingItems = useMemo(() => booking.bookingsItems.length > 0, [booking.bookingsItems]);

  const hasBookingSummary = useMemo(() => {
    const hasPendingRequests = relationships.propositionsGroups.some(({ status }) => ['DRAFT', 'OPEN'].includes(status));
    return hasBookingItems && !hasPendingRequests;
  }, [hasBookingItems, relationships.propositionsGroups]);

  const propositionsGroups = useMemo(() => relationships.propositionsGroups.filter(({ status }) => status !== 'CLOSED'), [relationships]);

  if (booking.bookingsItems.length === 0 && propositionsGroups.length === 0) {
    return (
      <Flex h="100%" flexDirection="column" alignItems="center" justifyContent="center">
        <ExploreCard maxW={712} booking={booking} />
      </Flex>
    );
  }

  function onSelect(id: PropositionsGroupJSON['id']) {
    searchParams.set('tab', 'requests');
    searchParams.set('propositionsGroupId', id.toString());

    navigate({
      pathname: `/bookings/${booking.id}`,
      search: searchParams.toString()
    });
  }

  return (
    <HStack w="100%" alignItems="initial" justifyContent="center" spacing={8}>
      <VStack w="100%" maxW={!hasBookingSummary && 758} spacing={8}>
        {
          propositionsGroups.length > 0 &&
            <PropositionsGroups
              relationships={{ ...relationships, propositionsGroups }}
              heading={t('steps.planning.pendingRequests.heading')}
              onClick={onSelect}
            />
        }

        {
          hasBookingItems &&
            <EventSchedule
              heading={t('schedule.heading')}
              bookingsItems={booking.bookingsItems}
              experiences={relationships.experiences}
              products={relationships.products}
              currency={bookingInvoice.currency}
              editDisabled={isBookingEditDisabled(booking, relationships)}
              onRemove={onRemoveBookingItem}
            />
        }

        <ExploreSection booking={booking} />
      </VStack>

      {
        hasBookingSummary &&
          <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={3}>
            <Heading size="md">{t('steps.planning.summary.heading')}</Heading>

            <BookingSummary
              booking={booking}
              relationships={relationships}
              currency={bookingInvoice.currency}
              onNext={onNext}
            />
          </VStack>
      }
    </HStack>
  );
}