import { Box, Divider, Grid, Heading, VStack, useToast } from '@chakra-ui/react';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { getBookingName } from '@jurnee/common/src/utils/bookings';
import { isBudgetVisible } from '@jurnee/common/src/utils/budgets';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import { BookingBudgetCard } from 'src/components/Budget/BookingBudgetCard';
import { useAppDispatch } from 'src/store';
import { ApprovalRequestData } from 'src/store/approvalRequests/approvalRequests.selectors';
import { ApprovalResponseUpdatePayload, getApprovalRequests, updateApprovalResponse } from 'src/store/approvalRequests/approvalRequests.thunks';
import { getBudgetsByIds } from 'src/store/budgets/budgets.selectors';
import { getUserSelector } from 'src/store/user/user.selectors';
import { getApproverApprovalResponse, isProcessedByApprover } from 'src/utils/approvalRequest';
import { ApprovalRequestDetails } from './ApprovalRequestDetails';
import ApprovalResponseForm from './ApprovalResponseForm';
import { ApprovalResponsesList } from './ApprovalResponsesList';
import { BookingItemsList } from './BookingItemsList';

interface Props {
  approvalRequest: ApprovalRequestData;
}

function isBudgetCardVisible(approvalRequest: ApprovalRequestData, userId: UserJSON['id']) {
  const [budget] = useSelector(getBudgetsByIds([approvalRequest.booking.budgetId]));

  if (isProcessedByApprover(approvalRequest, userId)) {
    return false;
  }

  if (approvalRequest.participantUsers.length > 0) {
    return true;
  }

  return budget && isBudgetVisible(budget, userId);
}

export function ApprovalRequestCard({ approvalRequest }: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('approvalRequests');

  const user = useSelector(getUserSelector);

  const approvalResponse = getApproverApprovalResponse(approvalRequest, user.id);
  const { subtotal, totalTax, totalDiscount, total } = getBookingInvoicesTotalDetails(approvalRequest.bookingsInvoices);
  const bookingTotal = subtotal - totalDiscount;

  const hasBudgetCard = isBudgetCardVisible(approvalRequest, user.id);

  async function handleUpdate(payload: Pick<ApprovalResponseUpdatePayload, 'approvalResponse' | 'data'>) {
    const status = payload.data.status.toLowerCase();

    try {
      await dispatch(updateApprovalResponse({...payload })).unwrap();

      toast(getSuccessToast(t(`toasts.${status}.success`)));

      if (payload.data.status === 'REJECTED') {
        // In case of multiple approval responses, other approval responses are canceled
        // We fetch all approval requests to have other approval responses up-to-date
        dispatch(getApprovalRequests());
      }
    } catch(error) {
      toast(getErrorToast(t(`toasts.${status}.error`), error));
    }
  }

  return (
    <Box w="100%" bg="white" border="1px solid" borderColor="blue.50" borderRadius={4} overflow="hidden">
      <Box px={5} py={3} borderBottom="1px solid" borderColor="blue.50">
        <Heading size="md">{getBookingName(approvalRequest.booking)}</Heading>
      </Box>

      <Grid minW={900} templateColumns="repeat(3, 1fr)">
        <VStack w="100%" p={5} borderRight="1px solid" borderColor="blue.50" spacing={5}>
          <BookingItemsList
            bookingsItems={approvalRequest.booking.bookingsItems}
          />

          <Divider />

          {
            hasBudgetCard && (
              <>
                <BookingBudgetCard
                  bookingTotal={bookingTotal}
                  budget={approvalRequest.booking.budget}
                  budgetBreakdown={approvalRequest.budgetBreakdown}
                  users={approvalRequest.participantUsers}
                  userBudgetBreakdowns={approvalRequest.userBudgetBreakdowns}
                  currency="EUR"
                />
                <Divider />
              </>
            )
          }

          <BookingPriceDetailsCard
            subtotal={subtotal}
            totalTax={totalTax}
            totalDiscount={totalDiscount}
            total={total}
            currency="EUR"
            showCurrencyWarning={true}
          />
        </VStack>

        <ApprovalRequestDetails approvalRequest={approvalRequest} />

        {
          isProcessedByApprover(approvalRequest, user.id) ? (
            <ApprovalResponsesList approvalRequest={approvalRequest} />
          ) : (
            <ApprovalResponseForm
              approvalResponse={approvalResponse}
              processNumber={approvalRequest.booking.processNumber}
              onUpdate={handleUpdate}
            />
          )
        }
      </Grid>
    </Box>
  );
}