import { Box, HStack, Input, Spacer, Tab, TabList, useToast } from '@chakra-ui/react';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { BackButton } from '@jurnee/common/src/components/buttons/BackButton';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { getBookingName, isBookingCommitted } from '@jurnee/common/src/utils/bookings';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { EditBookingDrawer } from 'src/drawers/EditBookingDrawer';
import { useAppDispatch } from 'src/store';
import { cancelBooking } from 'src/store/bookingDetails/bookingDetails.thunks';
import { updateBooking } from 'src/store/bookings/bookings.thunks';
import { isBookingCancelable } from 'src/utils/booking';

interface Props {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  tabs: { key: string, label: string | JSX.Element }[];
}

function BookingsLink() {
  const location = useLocation();

  return (
    <Link to={location.state?.prevPath || '/bookings'}>
      <BackButton />
    </Link>
  );
}

function BookingDetailsMenuCancel({ booking, relationships }: Pick<Props, 'booking' | 'relationships'>) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('booking');

  if (!isBookingCancelable(booking)) {
    return null;
  }

  if (isBookingCommitted(relationships)) {
    return null;
  }

  function onCancel() {
    dispatch(cancelBooking(booking));
  }

  return (
    <ConfirmModal
      title={t('modals.cancelBooking.title')}
      message={<Trans i18nKey='booking:modals.cancelBooking.message' values={{ name: booking.name }} />}
      buttonLabel={t('common:buttons.cancel')}
      onConfirm={onCancel}
    >
      <MenuItemWrapper icon="circleCloseLine" label={t('dotMenu.cancel')} />
    </ConfirmModal>
  );
}

function BookingDetailsMenu({ booking, relationships }: Pick<Props, 'booking' | 'relationships'>) {
  const { t } = useTranslation('booking');

  return (
    <DotMenu size="sm" bg="gray.50" _hover={{ background: 'gray.100' }} color="gray">
      <EditBookingDrawer booking={booking}>
        <MenuItemWrapper icon="edit" label={t('dotMenu.edit')} />
      </EditBookingDrawer>
      <BookingDetailsMenuCancel booking={booking} relationships={relationships}/>
    </DotMenu>
  );
}

export function BookingDetailsHeader(props: Props) {
  const dispatch = useAppDispatch();
  const [name, setName] = useState(getBookingName(props.booking));
  const toast = useToast();
  const { t } = useTranslation('booking');

  function onChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    setName(target.value);
  }

  async function onBlur() {
    if (isEmpty(name)) {
      return setName(props.booking.name);
    }

    if (props.booking.name === name) {
      return;
    }

    try {
      await dispatch(updateBooking({
        data: {
          name: name.replace(/\s+/g, ' ').trim()
        },
        id: props.booking.id
      }));

      toast(getSuccessToast(t('toasts.update.success')));
    } catch (err) {
      toast(getErrorToast(t('toasts.update.error')));
    }
  }

  return (
    <Box bg="white" w="100%">
      <HStack justifyContent="space-between" bg="white" spacing={2} w="100%" px={8} py={7}>
        <BookingsLink />

        <Input
          w="100%"
          value={name}
          onChange={onChange}
          onBlur={onBlur}
          fontSize={24}
          borderColor="transparent"
          _hover={{ borderColor: 'gray.200' }}
          _focus={{ borderColor: 'blue.400', outline: 'none' }}
          _focusVisible={{ outline: 'none' }}
          fontWeight={700}
          px={2}
        />

        <Spacer />

        <BookingDetailsMenu booking={props.booking} relationships={props.relationships} />
      </HStack>
      <TabList>
        { props.tabs.map(tab => <Tab key={tab.key}>{tab.label}</Tab>) }
      </TabList>
    </Box>
  );
}